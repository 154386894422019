import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Weather App Template | Weather App Design | Uizard"
    canonical="/templates/mobile-app-templates/weather-mobile-app-dark/"
    category="Tablet Templates"
    url="/templates/tablet-templates/weather-tablet-app-dark/"
    metaDescription="Design your own weather app with our new weather app template. To get started, simply sign up to Uizard Pro, open the template, and get to work!"
    description="
    h2:Say hello to Breeze, our weather app design template for tablet in dark mode
    <br/>
    Looking to design your own tablet weather app? Breeze, our brand new <a:https://uizard.io/templates/>UI design template</a> is here to fast-track your design flow. Designed with ease of use in mind, you can use Breeze as it is or adapt it to suit your vision. Try the template now with Uizard Pro!
    <br/>
    h3:Design like a pro come rain or shine!
    <br/>
    Tired of creating your <a:https://uizard.io/mockups/>UI mockups</a> from scratch? Uizard templates empower you to design like the professionals do, every single time. Design rapidly on your own or invite your team to join you with Uizard’s smart sharing functionality.
    <br/>
    h3:How to design a weather app - use our stunning new template!
    <br/>
    Designing your own weather app has never been so easy. Simply sign up to Uizard Pro, open our weather app template, and customize the design to suit your project. All our templates come with the core UX considerations and click journeys built in, meaning you can have a workable prototype built out in next to no time. The Breeze weather app template is also available for mobile!
    "
    pages={[
      "A chic and simplistic entry screen",
      "Weather forecast screen by country ",
      "User-generated 'my cities' overview screen",
      "Map screen with multi-city weather view",
    ]}
    projectCode="d9YJMvABa6HOBZeZELR9"
    img1={data.image1.childImageSharp}
    img1alt="weather tablet app dark design template cover"
    img2={data.image2.childImageSharp}
    img2alt="weather tablet app dark design template search screen"
    img3={data.image3.childImageSharp}
    img3alt="weather tablet app dark design template detail screen"
    img4={data.image4.childImageSharp}
    img4alt="weather tablet app dark design template city screen"
    img5={data.image5.childImageSharp}
    img5alt="weather tablet app dark design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/weather-tablet-app-dark/weather-tablet-dark-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/weather-tablet-app-dark/weather-tablet-dark-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/weather-tablet-app-dark/weather-tablet-dark-detail.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/weather-tablet-app-dark/weather-tablet-dark-madrid.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/weather-tablet-app-dark/weather-tablet-dark-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
